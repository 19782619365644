import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import message from 'antd/es/message'

import { Container, Typography, Icon, Button } from 'components'
import API from 'config/apiAgenda'
import { useQuery } from 'hooks'

const initialState = {
  isReservation: false,
  successfulCancellation: false,
  reservation: null,
  appointment: null,
  loading: true,
  error: null,
  showConfirmationButton: false,
  siteKey: null,
}

const appointmentStatuses = {
  confirmar: 8,
  cancelar: 1,
}

// eslint-disable-next-line max-lines-per-function
function Confirmation() {
  const [state, setState] = useState(initialState)
  const { operation, token } = useParams()
  const query = useQuery()
  const params = query.get(`reserva`)
  const appointmentStatusId = appointmentStatuses[operation]

  const recaptchaRef = useRef(null)

  const {
    appointment,
    isReservation,
    reservation,
    successfulCancellation,
    loading,
    error,
    showConfirmationButton,
  } = state

  async function handleCancelReservation() {
    if (!recaptchaRef.current) {
      message.error(`reCAPTCHA no está listo. Por favor, intente nuevamente.`)
      return
    }

    try {
      const token = await recaptchaRef.current.executeAsync()
      if (token) {
        await cancelReservation(token)
      } else {
        message.error(
          `No se pudo obtener el token de reCAPTCHA. Por favor, intente nuevamente.`,
        )
      }
    } catch (error) {
      message.error(
        `Error al verificar reCAPTCHA. Por favor, intente nuevamente.`,
      )
    }
  }

  async function cancelReservation(googleToken) {
    try {
      const reservation = await API.cancelReservation({
        token,
        isCancellation: true,
        googleToken,
      })
      setState({
        loading: false,
        isReservation: true,
        successfulCancellation: true,
        showConfirmationButton: false,
        reservation,
      })
    } catch (e) {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset()
      }
      const error = typeof e === `string` ? e : `No existe`
      setState({
        loading: false,
        isReservation: true,
        error,
        showConfirmationButton: false,
      })
    }
  }

  useEffect(() => {
    async function getInvisibleSiteKey() {
      const response = await API.getInvisibleSiteKey()
      setState({
        ...state,
        loading: false,
        isReservation: true,
        showConfirmationButton: true,
        siteKey: response.invisibleCaptchaSiteKey,
      })
    }
    async function confirmAppointment() {
      try {
        const appointment = await API.confirmAppointment({
          token,
          appointmentStatusId,
        })
        setState({
          appointment,
          loading: false,
          error: null,
        })
      } catch (e) {
        const error = typeof e === `string` ? e : `No existe`
        setState({
          ...state,
          loading: false,
          error,
        })
      }
    }
    if (params) {
      getInvisibleSiteKey()
    } else {
      confirmAppointment()
    }
  }, [])

  return (
    <Container width="100%" justifyContent="center">
      {loading ? (
        <Icon icon="spinner" color="primary.2" fontSize="9" spin />
      ) : (
        <Container padding="4" width="100%">
          <Container width="100%" justifyContent="center">
            <Container
              marginTop="6"
              width="60%"
              backgroundColor="primary.0@0.2"
              border="1px solid"
              borderColor="primary.0"
              borderRadius="6px"
              justifyContent="center"
              alignItems="center"
              padding="4"
              fontSize="4"
              color="primary.0"
              flexDirection={showConfirmationButton ? `column` : `row`}
            >
              {!isReservation ? (
                <AppointmentDetails
                  error={error}
                  appointmentStatusId={appointmentStatusId}
                  appointment={appointment}
                />
              ) : (
                <>
                  <ReservationDetails
                    error={error}
                    reservation={reservation}
                    successfulCancellation={successfulCancellation}
                    showConfirmationButton={showConfirmationButton}
                    handleCancel={handleCancelReservation}
                  />
                  {state.siteKey && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={state.siteKey}
                      size="invisible"
                    />
                  )}
                </>
              )}
            </Container>
          </Container>
        </Container>
      )}
    </Container>
  )
}

function AppointmentDetails({ appointment, error, appointmentStatusId }) {
  if (error) {
    return (
      <>
        <Icon icon="info" color="primary.0" fontSize="9" marginRight="2" />
        <Typography>La sesión ha expirado o ya se ha confirmado</Typography>
      </>
    )
  }
  if (appointmentStatusId === 1 && appointment) {
    return (
      <>
        <Icon icon="check" color="primary.0" fontSize="9" marginRight="2" />
        <Typography>La hora se ha anulado correctamente</Typography>
      </>
    )
  }
  if (appointmentStatusId === 8 && appointment) {
    return (
      <>
        <Icon icon="check" color="primary.0" fontSize="9" marginRight="2" />
        <Typography>La hora se ha confirmado correctamente</Typography>
      </>
    )
  }
  return null
}

function ReservationDetails({
  reservation,
  successfulCancellation,
  error,
  showConfirmationButton,
  handleCancel,
}) {
  if (error) {
    return (
      <>
        <Icon icon="info" color="primary.0" fontSize="9" marginRight="2" />
        <Typography>{error}</Typography>
      </>
    )
  }
  if (showConfirmationButton && !successfulCancellation) {
    return (
      <>
        <Typography>
          ¿ Está seguro que quiere cancelar su reserva de forma permanente ?
        </Typography>
        <Button
          width={{ _: `100%`, md: `50%` }}
          hoverProps={{ backgroundColor: `primary.2@0.9` }}
          margin="0 auto"
          marginY="3"
          padding="3"
          withShadow
          backgroundColor={`primary.2`}
          onClick={handleCancel}
        >
          <Typography textDecoration="underline" color={`white`} fontSize="4">
            Sí, cancelar reserva
          </Typography>
        </Button>
      </>
    )
  }

  if (reservation || successfulCancellation) {
    return (
      <>
        <Icon icon="check" color="primary.0" fontSize="9" marginRight="2" />
        <Typography>La hora se ha anulado correctamente</Typography>
      </>
    )
  }
}

export default Confirmation
